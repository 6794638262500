<!--

      This view is the default view when opening the app. It enables to
      start the createplan wizard to create a new menu or to reopen the last
      menu.

 -->

<template>
  <Page title='Menü planen' :hideback="true" :shoppingList="true">
    <img src="../../assets/img/mealkit.svg" class="menu-img" alt="">
    <div class="section-title">Meine neue Bestellung</div>
    <div class="white-box">
      <div @click="this.$store.dispatch('openModal', {name:'menuplanningdayshelp'})">
        <div class="main-text">Wie viele Rezepte sollen in deine nächste Kochbox?
          <Icon name="helpCircleOutline" class="menu-help-icon" />
        </div>
      </div>
      <div class="number-selection">
        <div class="number-item">
          <div class="btn-number" @click="changePlannedDays('minus')">
            <Icon name="removeOutline" class="btn-number-icon" />
          </div>
          <div class="the-number" data-cy="plan-days">
            {{ $store.state.planneddays }}
          </div>
          <div class="btn-number" @click="changePlannedDays('plus')">
            <Icon name="addOutline" class="btn-number-icon" />
          </div>
        </div>
      </div>
      <div class="btn-primary standalone" @click="createPlanWizard()" data-cy="go">Los geht's</div>
    </div>
    <div v-if="$store.state.newplan || $store.state.removingNeworderAndNewplan">
      <div class="section-title">
        <div class="headline">Zuletzt geöffnet</div>
      </div>
      <div class="menu-cards">
        <MenuCard
          v-if="!$store.state.removingNeworderAndNewplan"
          :openPlan="$store.state.newplan"
          :key="$store.state.newplan.id"
          />
        <MenuCard
          v-if="$store.state.removingNeworderAndNewplan"
          :skeletonMode="true"
          />
      </div>
    </div>
    <div v-if="showSavedPlans">
      <div class="section-title">
        <div class="headline">Gespeichert</div>
      </div>
      <div class="menu-cards">
        <MenuCard
          v-for="savedPlan in savedPlansSorted"
          :key="savedPlan.id"
          :openPlan="savedPlan"
          />
      </div>
    </div>
    <div 
      class="btn-tertiary" 
      @click="$store.dispatch('goTo', 'menus')"
      v-if="showShowMore"
    >
      Alle nicht gekauften Menüs anzeigen
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import MenuCard from '@/components/cards/MenuCard.vue'

export default {
  name: 'shoppingtab',
  components: {
    Page,
    MenuCard
  },
  computed: {
    openPlansSorted () {
      if (this.$store.state.openPlans) {
        return this.$store.state.openPlans.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)).slice(Math.max(this.$store.state.openPlans.length - 3, 0))
      }
    },
    savedPlansSorted () {
      if (this.$store.state.savedPlans) {
        return this.$store.state.savedPlans.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)).slice(Math.max(this.$store.state.savedPlans.length - 3, 0))
      }
    },
    showSavedPlans () {
      if (this.$store.state.savedPlans) {
        return this.$store.state.savedPlans && this.$store.state.savedPlans.length > 0
      }
    },
    showShowMore () {
      if (this.$store.state.openPlans) {
        return this.$store.state.openPlans.length > 1
      }
    }
  },
  methods: {
    changePlannedDays (method) {
      let newVal = this.$store.state.planneddays
      if (method === 'minus' && newVal > 3) {
        newVal -= 1
      } else if (method === 'plus' && newVal < 14) {
        newVal += 1
      } else if (method === 'minus' && newVal <= 3) {
        // Inform the user why 3 is the minimum
        this.$store.dispatch('openModal', {name: 'planningisgood'})
      }
      this.$store.dispatch('save', { key: 'planneddays', value: newVal })
    },

    async createPlanWizard () {
      await this.createNewplan()
      this.$store.dispatch('goTo', 'checkout')
      this.$store.dispatch('logAnalyticsEvent', { name: 'created_new_menu' })
    },

    // Generate a new meal plan
    async createNewplan () {
      setTimeout(async () => await this.$store.dispatch('removeNewOrderLocally'), 500)
      return this.artificialDelay(async () => {
        return this.$store.dispatch('generateMealPlan').then(async result => {
          if (!result) {
            this.$store.dispatch('goTo', 'shopping')
          } else {
            await this.$store.dispatch('refreshExistingOrder')
          }
          this.$store.dispatch('deleteOrderReminderDripNotifications')
          
          // Since we have a new order, we must update the open orders array
          // by adding the new order.
          // Either we can download everything again with:
          // this.$store.dispatch('getOpenOrdersAndPlans')
          // Or we perform this task on the frontend side:
          this.$store.state.openPlans.unshift(this.$store.state.newplan)
          this.$store.state.openOrders.unshift(this.$store.state.neworder)
          this.$store.dispatch('save', { key: 'openPlans', value: this.$store.state.openPlans })
          this.$store.dispatch('save', { key: 'openOrders', value: this.$store.state.openOrders })
        })
      }, 1000)
    },

    // Cause a time delay. If the meal plan generation is too fast, continue
    // to show the spinning wheel screen
    artificialDelay (task, delay) {
      this.$store.dispatch('save', { key: 'generatingPlan', value: true })
      const starting = new Date()
      task().then(() => {
        let passedTime = new Date() - starting
        let remainingTime = 0
        if (passedTime < delay) {
          remainingTime = delay - passedTime
        }
        setTimeout(() => {
          this.$store.dispatch('save', { key: 'generatingPlan', value: false })
        }, remainingTime)
      })
    }
  },
  updated () {
    this.showAddMenu = false
    this.$store.dispatch('save', { key: 'tab', value: 'shopping' })
    if (!this.$store.state.planneddays) {
      this.$store.dispatch('save', { key: 'planneddays', value: 7 })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.menu-img {
  max-height: 40vh;
  max-width: 75%;
  margin: 20px;
}

.menu-help-icon {
  display: inline-block;
  border-radius: 50px;
  width: 16px;
  height: 16px;
  margin-left: 6px;
  margin-bottom: -4px;
  --stroke: $col-font;
  stroke: $col-font;
  fill: $col-font;
}

.menu-cards {
  display: flex;
  flex-direction: column-reverse;
  transition: ease-in-out .5s;
  margin-bottom: 5px;
}

.newplans-title-count {
  color: $col-font;
  margin-left: 10px;
}

.red-corner-bg {
  background: linear-gradient(to bottom left,  $col-red 50%,transparent 50%) 100% 0/70px 70px, $col-orange;
  background-repeat: no-repeat;
}

.red-corner {
  position: absolute;
  right: -5px;
  top: 5px;
  transform: rotate(45deg);
  color: white;
  overflow: hidden;
  padding: 0 4px;
  line-height: 1;
}

.add-menu-card {
  box-shadow: none !important;
  background: white;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 15px !important;
  margin-top: 15px;
}

.number-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.number-item {
  display: flex;
  border-radius: #{$border-radius};
  background: $col-white;
}

.the-number {
  @extend .headline;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
}
</style>
